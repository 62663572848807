<template>
  <v-btn-toggle
    id="btnToggle"
    :class="className"
    :value="value"
    active-class="btn-toggle_active"
    class="btn-toggle p-sign-up_second-step_btn-toggle"
    @change="onChange"
  >
    <v-btn
      v-for="item in values"
      :key="item.key"
      :class="{
        'btn-toggle_three-btns': values.length === 3,
        'btn-toggle_two-btns': values.length === 2,
      }"
      :value="item.key"
      height="100%"
    >
      <span class="capitalize"> {{ item.value }} </span>
    </v-btn>
  </v-btn-toggle>
</template>
<script>
export default {
  name: "Switcher",
  props: {
    className: {
      default: "",
      type: String,
    },

    value: [String, Boolean],
    items: { required: true, type: Object },
    fieldName: { default: "", type: String },
  },
  methods: {
    onChange(value) {
      this.$emit("change", value, this.fieldName);
      this.$emit("input", value);
    },
  },
  computed: {
    values() {
      if (!this.items) {
        return [];
      }
      return Object.keys(this.items).map((key) => ({
        key,
        value: this.items[key],
      }));
    },
  },
};
</script>
