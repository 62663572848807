<template>
  <UiKit />
</template>

<script>
import UiKit from "@/components/uikit";

export default {
  name: "uiKit",
  components: {
    UiKit,
  },
};
</script>
