<template>
  <div class="uikit">
    <v-container fluid>
      <h1>Component Library</h1>
      <v-row align="center">
        <v-col cols="3">
          <div class="uikit_column">
            <h3>Base input (47px height)</h3>
            <BaseInput v-model="input" placeholder="Placeholder" @change="inputChange" />
            <p>{{ input }}</p>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="uikit_column">
            <h3>Base input with validation</h3>
            <BaseInput
              v-model="validationInput"
              placeholder="Enter your email"
              @change="validationInputChange"
              @blur="$v.validationInput.$touch()"
              :errors="validationInputErrors"
            />
            <p>{{ input }}</p>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="uikit_column">
            <h3>Switcher</h3>
            <Switcher v-model="switcher" :items="$t('general.genders')" @change="switcherChange" />
            <p>{{ switcher }}</p>
          </div></v-col
        >
      </v-row>
      <v-row>
        <v-col>
          <div class="uikit_column">
            <h3>Disabled input</h3>
            <BaseInput v-model="disabledInput" :disabled="true" placeholder="Disabled" /></div
        ></v-col>
        <v-col>
          <div class="uikit_column">
            <h3>Prefilled input</h3>
            <BaseInput v-model="prefilledInput" placeholder="Placeholder" @change="prefilledInputChange" /></div
        ></v-col>
        <v-col> </v-col>
        <v-col> </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="uikit_column">
            <h3>State Select</h3>
            <StateSelect v-model="state" :errors="stateErrors" @change="stateSelectChange" @blur="$v.state.$touch()" />
            <p>{{ state }}</p>
          </div>
        </v-col>
        <v-col>
          <div class="uikit_column">
            <h3>Password input</h3>
            <PasswordInput
              v-model="password"
              :isPasswordHelper="true"
              :errors="passwordErrors"
              @change="passwordInputChange"
              @blur="$v.password.$touch()"
            />
            <p>{{ password }}</p>
          </div></v-col
        >
        <v-col>
          <div class="uikit_column">
            <h3>Phone input</h3>
            <PhoneInput
              v-model="phoneNumber"
              :errors="phoneNumberErrors"
              placeholder="Contact Number"
              @change="phoneNumberChange"
              @blur="$v.phoneNumber.$touch()"
            />
            <p>{{ phoneNumber }}</p>
          </div></v-col
        >
        <v-col>
          <div class="uikit_column">
            <h3>Date Picker</h3>
            <DatePicker
              v-model="date"
              :errors="dateErrors"
              placeholder="Date of birth"
              :dense="true"
              :height="47"
              @setDate="dateChange"
              @blur="$v.date.$touch()"
            />
            <p>{{ date }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <Textarea
            v-model="textarea"
            :errors="textareaErrors"
            @change="textareaChange"
            @blur="$v.textarea.$touch()"
            placeholder="Long text Input"
        /></v-col>
        <v-col cols="6"> <Textarea placeholder="Long text Input" :disabled="true" /></v-col>
      </v-row>
      <h2>Buttons / Extra-LG</h2>
      <v-row>
        <v-col cols="6">
          <PrimaryButton size="x-large" text="Full Width Extra-Large Button" width="100%" />
        </v-col>
        <v-col cols="6">
          <PrimaryButton size="x-large" text="Full Width Disabled Button" width="100%" :disabled="true" />
        </v-col>
      </v-row>
      <h2>Buttons / LG</h2>
      <v-row>
        <v-col>
          <PrimaryButton text="Primary button" size="large" />
        </v-col>
        <v-col>
          <PrimaryButton text="Rounded" size="large" :rounded="true" />
        </v-col>
        <v-col>
          <PrimaryButton text="Disabled button" size="large" :disabled="true" />
        </v-col>
        <v-col>
          <OutlinedButton text="Outlined button" size="large" />
        </v-col>
        <v-col>
          <OutlinedButton text="Disabled button" size="large" :disabled="true" />
        </v-col>
        <v-col>
          <OutlinedButton text="Outlined button" size="large" icon="mdi-eye" :rounded="true" />
        </v-col>
        <v-col>
          <OutlinedButton text="Disabled button" size="large" icon="mdi-eye" :rounded="true" :disabled="true" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <PrimaryButton text="Full width primary button" size="large" :fullWidth="true" />
        </v-col>
        <v-col cols="6">
          <PrimaryButton text="Full width disabled primary button" size="large" :fullWidth="true" :disabled="true" />
        </v-col>
      </v-row>
      <h2>Buttons / MD</h2>
      <v-row>
        <v-col>
          <PrimaryButton text="Primary button" size="medium" />
        </v-col>
        <v-col>
          <PrimaryButton text="Iconic button" size="medium" leftIcon="mdi-plus" />
        </v-col>
        <v-col>
          <PrimaryButton text="Iconic button disabled" size="medium" leftIcon="mdi-plus" :disabled="true" />
        </v-col>
        <v-col>
          <PrimaryButton text="Rounded" size="medium" :rounded="true" />
        </v-col>
        <v-col>
          <PrimaryButton text="Disabled button" size="medium" :disabled="true" />
        </v-col>
        <v-col>
          <OutlinedButton text="Outlined button" size="medium" />
        </v-col>
        <v-col>
          <OutlinedButton text="Disabled button" size="medium" :disabled="true" />
        </v-col>
      </v-row>
      <h2>Buttons / SM</h2>
      <v-row>
        <v-col>
          <PrimaryButton text="Primary button" size="small" />
        </v-col>
        <v-col>
          <PrimaryButton text="Iconic-R button" size="small" rightIcon="mdi-arrow-right" />
        </v-col>
        <v-col>
          <PrimaryButton text="Iconic-R button" size="small" rightIcon="mdi-arrow-right" :disabled="true" />
        </v-col>
        <v-col>
          <PrimaryButton text="Rounded" size="small" :rounded="true" />
        </v-col>
        <v-col>
          <PrimaryButton text="Disabled button" size="small" :disabled="true" />
        </v-col>
        <v-col>
          <OutlinedButton text="Iconic-L button" size="small" icon="mdi-pencil" />
        </v-col>
        <v-col>
          <OutlinedButton text="Iconic-L button" size="small" icon="mdi-pencil" :disabled="true" />
        </v-col>
        <v-col>
          <OutlinedButton text="Outlined button" size="small" />
        </v-col>
        <v-col>
          <OutlinedButton text="Disabled button" size="small" :disabled="true" />
        </v-col>
      </v-row>
      <h2>Form Elements</h2>
      <v-row>
        <v-col cols="2">
          <TimePicker v-model="time" />
        </v-col>
        <v-col cols="3">
          <Combobox :items="combobox" placeholder="Add some chips here..." @change="onComboboxChange" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <InputGroup
            v-model="inputGroup"
            secondInputType="text"
            buttonText="Add something"
            placeholderFirst="Add a Row Input"
            placeholderSecond="Description..."
            @change="onInputGroupChange"
        /></v-col>
        <v-col>
          <InputGroup
            v-model="inputGroup"
            secondInputType="year"
            buttonText="Add something"
            placeholderFirst="Add a Row Input"
            placeholderSecond="Description..."
            :disabled="true"
        /></v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { email, required } from "vuelidate/lib/validators";

import DatePicker from "../shared/DatePicker";
import InputGroup from "../shared/InputGroup";
import PasswordInput from "../shared/PasswordInput";
import PhoneInput from "../shared/PhoneInput";
import StateSelect from "../shared/StateSelect";
import TimePicker from "../shared/TimePicker";
import BaseInput from "./BaseInput";
import Combobox from "./Combobox";
import OutlinedButton from "./OutlinedButton";
import PrimaryButton from "./PrimaryButton";
import Switcher from "./Switcher";
import Textarea from "./Textarea";

const passwordMinLength = (value, vm) => {
  return value.length >= 6;
};
export default {
  name: "uikit",
  components: {
    BaseInput,
    Switcher,
    StateSelect,
    PasswordInput,
    PrimaryButton,
    PhoneInput,
    DatePicker,
    Textarea,
    OutlinedButton,
    TimePicker,
    Combobox,
    InputGroup,
  },
  validations: {
    password: { required, passwordMinLength },
    validationInput: { required, email },
    state: { required },
    phoneNumber: { required },
    date: { required },
    textarea: { required },
  },
  data: () => ({
    input: "",
    prefilledInput: "Prefilled input",
    disabledInput: "",
    validationInput: "",
    switcher: "",
    state: "",
    password: "",
    phoneNumber: "",
    date: null,
    textarea: "",
    time: "",
    combobox: [],
    inputGroup: {
      first: "",
      second: "",
    },
  }),
  computed: {
    validationInputErrors() {
      const errors = [];
      if (!this.$v.validationInput.$dirty) return errors;
      !this.$v.validationInput.required && errors.push("Email is required");
      !this.$v.validationInput.email && errors.push("Email must be valid");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required &&
        errors.push(this.$t("validation.required", { input: this.$t("general.inputs.state") }));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.required && errors.push("Phone number is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.password") }));
      !this.$v.password.passwordMinLength && errors.push(this.$t("validation.passwordMinLength"));
      !this.$v.password.passwordValid && errors.push(this.$t("validation.passwordMustApply"));
      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;
      !this.$v.date.required && errors.push("Date is required");
      return errors;
    },
    textareaErrors() {
      const errors = [];
      if (!this.$v.textarea.$dirty) return errors;
      !this.$v.textarea.required && errors.push("The input is required");
      return errors;
    },
  },
  methods: {
    inputChange(input) {
      this.input = input;
    },
    validationInputChange(input) {
      this.validationInput = input;
    },
    switcherChange(switcher) {
      this.switcher = switcher;
    },
    stateSelectChange(state) {
      this.state = state;
    },
    passwordInputChange(password) {
      this.password = password;
    },
    phoneNumberChange(phoneNumber) {
      this.phoneNumber = phoneNumber;
    },
    dateChange(date) {
      this.date = date;
    },
    prefilledInputChange(input) {
      this.prefilledInput = input;
    },
    textareaChange(value) {
      this.textarea = value;
    },
    onComboboxChange(items) {
      this.combobox = items;
    },
    onInputGroupChange(value, fieldName) {
      this.inputGroup[fieldName] = value;
    },
  },
};
</script>
<style lang="scss">
.uikit {
  width: 100%;
  height: 100vh;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &_column {
    display: flex;
    flex-direction: column;
  }
}
</style>
