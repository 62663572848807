<template>
  <v-row no-gutters>
    <v-col class="input-group">
      <v-row no-gutters>
        <v-col :cols="cols.first">
          <v-text-field
            :value="value.first"
            hide-details
            class="input-group_input input-group_input__left border"
            solo
            flat
            height="30"
            dense
            :placeholder="placeholderFirst"
            @input="onChangeFirst"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols.second">
          <v-text-field
            v-if="secondInputType === 'text'"
            :value="value.second"
            hide-details
            class="input-group_input input-group_input__right border"
            solo
            flat
            height="30"
            dense
            :placeholder="placeholderSecond"
            @input="onChangeSecond"
          >
          </v-text-field>
          <v-menu
            v-else
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="200px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="input-group_input input-group_input__right"
                :value="value.second"
                placeholder="Year (Optional)"
                solo
                flat
                readonly
                dense
                hide-details
                v-on="on"
              >
                <template v-slot:append>
                  <img src="../../assets/calendar.svg" />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              no-title
              @input="onYearChange"
              :max="maxYear"
              :min="minYear"
              reactive
              color="primary"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2" order-md="1">
      <v-btn
        class="input-group-btn primary-btn"
        depressed
        elevation="0"
        :dark="!disabled"
        :ripple="false"
        @click="onSubmit"
        :disabled="disabled"
      >
        <span>{{ buttonText }}</span>
        <v-icon class="input-group-btn-icon">mdi-plus</v-icon></v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "InputGroup",
  props: {
    value: { required: true, type: Object },
    secondInputType: String,
    buttonText: String,
    placeholderFirst: String,
    placeholderSecond: String,
    disabled: { default: false, type: Boolean },
  },
  data: () => ({
    menu: false,
    maxYear: new Date().toISOString().substr(0, 10),
    minYear: new Date("1920").toISOString().substr(0, 10),
  }),
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    cols() {
      return {
        first: this.secondInputType === "text" ? 3 : 9,
        second: this.secondInputType === "text" ? 9 : 3,
      };
    },
  },
  methods: {
    onChangeFirst(value) {
      this.$emit("change", value, "first");
    },
    onChangeSecond(value) {
      this.$emit("change", value, "second");
    },
    onYearChange(value) {
      const year = value.split("-")[0];
      this.menu = false;
      this.$emit("change", year, "second");
    },
    onSubmit() {
      this.$emit("submit");
    },
  },
};
</script>
<style lang="scss">
.input-group {
  :focus-within {
    .input-group_input {
      border: 1px solid $primary !important;
      &__left {
        border-right: none !important;
      }
      &__right {
        border-right: 1px solid $primarylight !important;
      }
    }
  }
  &_input {
    font-style: normal;
    border: 1px solid $primarylight;
    font-weight: 600;
    font-size: 12px;
    line-height: 6px;
    color: $primarylight3;

    &__right {
      border-radius: 0;
      border-left: none;
      border-right: none;
    }
    &__left {
      border-radius: 4px 0 0 4px;
    }
    .v-input__control .v-input__slot .v-text-field__slot input::placeholder {
      font-weight: normal;
    }
  }

  &-btn {
    width: 100%;
    height: 40px !important;
    border-radius: 0 4px 4px 0;
    padding: 0 0 0 16px !important;

    .v-btn__content {
      font-size: 12px;
      display: inline-flex;
      justify-content: space-between;
      height: 40px;
      font-size: 12px;
      .input-group-btn-icon {
        min-width: 10%;
        border-radius: 4px;
        padding: 0 8px;
        height: 100%;
        background: $black-grb;
      }
    }
  }
}
</style>
